.navbarContainer {
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    margin: 10px 0px 0px 0px!important;
    padding: 10px 0px;
}

.navbarContainer__row {
    --bs-gutter-x: 0!important;
    padding-right: 0px!important;
}

.navbar__icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    padding-right: 20px;
}

.navbar__icons--image {
    max-height: 50px;
    opacity: 1;
}


.navbar__icons--image:hover {

    cursor: pointer;
    opacity: .4;
    transition: all .25s ease-in-out;

}


.section {

    padding: 2rem;
    padding-top: 4rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 100vw;
    min-height: 100vh;
    text-align: center;

}

.panel  {

    position: fixed;
    z-index: 998;
    background-color: rgba(255,255,255, .95);
    box-shadow: -5px 0px 10px rgba(0,0,0, .5);
    width: 50%;
    height: 100%;
    top: 0;
    /* bottom: 0; */
    /* left: 0; */
    right: 0;

    transition: transform .3s ease;
    /* de arriba */
    transform: translate(0,+100%);
    /* de arriba */
    /* transform: translate(0,100%); */
    /* de derecha */
    transform: translate(+100%, 0); 
    /* de izquier */
    /* transform: translate(-100,0%); */
}

.panel.is-active{

    transition: transform .3s ease;
    transform: translate(0,0);
}

.panel-btn {
    position: fixed;
    z-index: 999;
    top: 1vh;
    right: 1vw;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-items: center;
    align-items: center;
    background-color: var(--main-color);
}

.menu {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    align-items: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.menu__items {
    padding: 1rem;
    width: inherit;
    display: block;
    font: 1.5rem;
    text-decoration: none;
    color: #000;
    transition: all 0.3s ease;
}
.menu__items--lines {
    border-bottom: 1px solid rgba(0,0,0, .5);
    width: 30%;

}
.menu a:hover {
    font-weight: bold;
    color: var(--over-color);
    background-color: var(--main-modal-color);
}