.footer__container {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
}
.footer__columna { width: 47.45%;  padding-top: 40px;}
.footer__separador { width: 5.1%; }

.footerTextos {

    font-family: 'Montserrat';
    font-size: 1rem;
    text-transform: uppercase;
    line-height: 1.5rem;

}

.footerTextos__izq {
    text-align: right!important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}


.footerTextos__der {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footerItem {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.footerItem  .footerItem__backgroundHover {
    width: 92.5%;
    height: 100px;

    position: absolute;
    top: 300px;
    left: 12px;
    padding-left: calc(1.5rem * .5);
    padding-right: calc(1.5rem * .5);
    
    font-size: 2rem;
    text-align: center;
    font-family: 'Montserrat';


    background-color: rgba(243,114,35,.5)!important;
    transition: all 300ms;

    display: flex;
    justify-content: center;
    align-items: center;
    

}

.footerItem .footerItem__backgroundHover h4 {

    position: relative;
    color: white;
    font-family: "Montserrat", serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.4rem;
    
}


.footerItem:hover .footerItem__backgroundHover {
    top: 0px;
    height: 100%;
}


@media (max-width : 1024px) {
    .footerTextos{ font-size: .9rem; }
    .footer__separador { opacity: 0; }
}

@media (max-width : 768px) { }

@media (max-width : 576px) {
    .footer__columna { width: 100%; padding-top: 10px;  }
    .footer__separador {display: none;}
    .footerTextos__izq { flex-direction: row-reverse; }
    .footerTextos__der { flex-direction: row;}
}