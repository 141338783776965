.carousel-control-next-icon, .carousel-control-prev-icon {

    width: 7rem!important;
    height: 5rem!important;

}

.bannerXtres__container {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
}
.bannerXdos__container{
    padding-top: 0rem;
    padding-bottom: 1.2rem;
}

.bannerXtres__banner { width: 33.3%; }

.bannerXdos__banner { width: 47.45%;  }
.bannerXdos__medium { width: 5.1%; }
.bannerXdos__medium--image {
    height: 100%!important;
}

@media (max-width : 768px) {
    .bannerXdos__banner { width: 50%;  }
    .bannerXdos__medium { display: none;  }
}
@media (max-width : 576px) {

    .bannerXtres__container {
        padding-top: .6rem;
        padding-bottom: .6rem;
    }
    .bannerXdos__container{
        padding-top: 0rem;
        padding-bottom: 1.2rem;
    }

    .bannerXtres__banner--izq { padding-right: 2px;}
    .bannerXtres__banner--cen { padding-right: 7px; padding-left: 7px;}
    .bannerXtres__banner--der { padding-left: 2px }

    .bannerXdos__banner--izq { padding-right: 6px;}
    .bannerXdos__banner--der { padding-left: 6px;}
    .bannerXdos__banner { width: 50%;  }
    .bannerXdos__medium { display: none;  }
}