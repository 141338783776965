.bannerContainer {
    animation-name: animationOut;
    animation-duration: .5s;
}

.bannerContainer:hover { 

    animation-name: animationOver;
    animation-duration: .5s;
}

.bannerContainer__border {
    border: 1px solid rgba(0,0,0,.5);
}

@keyframes animationOver {
    from {
           opacity: 1;     
       }
    to {
          opacity: .5;
      }
}

@keyframes animationOut {
    from {
            opacity: .5;     
        }
    to {
            opacity: 1;
        }
}