.box {
    border: 1px solid orange;
}

:root {

    --main-font: sans-serif;
    --font-size: 16px;
    --main-color: rgb(0,183,177);
    --titles-color: #606060;
    --main-modal-color: rgb(0,183,177);;
    --second-modal-color: rgba(255,34,157, .7);
    --container-width: 1200px;
    --over-color: black;
    --locales-background: rgb(172, 190, 205);

}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&display=swap');

/* animaciones  */

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fast {
    -webkit-animation-duration: 0.9s;
    animation-duration: 0.9s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}

.carousel__box--image {
    object-fit: cover;
    /* object-position: center;
    height: 400px; */
}

.quienesSomos__titulo {
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-weight: 400;
    letter-spacing: 1.4rem;
    font-size: 3rem;
    margin-top: 50px;
    margin-bottom: 30px;
    color: var(--titles-color);
}

.quienesSomos__texto {
   
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 1rem;
    line-height: 2rem;
    padding: 0px 30px;
}

.quienesSomos__img--footer {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 50px 0;
}

.quienesSomos__img--footer img {
    width: 50%;
}

.gastronomia__container {
    padding: 40px;
}

.gastronomia__column {
    width: 20%;
    flex: 20%;
    margin: 0px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.gastronomia__column--item {
    border: 1px solid black;
}

.gastronomia__column--item--cont {  position: relative; }
.gastronomia__column--item--imgOver {position: absolute;}

.gastronomia__column--itemBottom { border-bottom: none; }
.gastronomia__column--itemTop { border-top: none; }
.gastronomia__column--itemRight { border-right: 1px solid #000; }

.gastronomia__column--item--img { width: 100%; transition: all .5s ease;}


.gastronomia__column--item--img:hover {
    opacity: .5;
}

.locales__back {
    margin-top: 40px;
    background-color: var(--locales-background);
}

.locales__block {
    padding: 0!important;
    --bs-gutter-x: 0px!important
}

.locales__column {
    /* width: fit-content; */
    background-color: rgb(172, 190, 205);
    /* border: 1px solid green; */
}

.locales__img {
   /* max-height: 650px; */
   object-fit: contain;
}

.locales__banner {
    border: 1px solid rgba(0,0,0,0.4);
}
.quienesSomos__img--footerLocales {
    background-color: rgb( 142,165, 183 );
    margin: 0;
}

@media (max-width: 1024px) {}

@media (max-width: 768px) {

    .quienesSomos__titulo {
        font-size: 2rem;
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .quienesSomos__texto {
        padding: 0px 20px;
    }
}

@media (max-width : 576px) {

    .carousel__box--image {
        object-fit: cover;
        object-position: center;
        height: 400px;
    }

    .container, .container-sm {
        max-width: 740px!important;
    }
    .quienesSomos__titulo {
        flex-direction: column;
        font-size: 2rem;
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: center;
        letter-spacing: .7rem;
    }

    .quienesSomos__titulo img {
        width: 40%;
    }
    .quienesSomos__img--footer img {
        width: 75%;
    }
    .quienesSomos__img--footer {
        display: flex;
        align-items: center;
        justify-content: end;
        margin: 50px 0;
    }

    .gastronomia__column {
        max-width: 50%;
        flex: 50%;
        margin: 0px;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }
}



/* 

// SIZE XXL
// @media (max-width: 1400px) {}
// // SIZE XL
// @media (max-width: 1200px) {}
// // SIZE L
// @media (max-width: 1024px) {}
// // SIZE MD
// @media (max-width: 768px) {}
// // SIZE XS
// @media (max-width : 576px) {}

 */
